<template lang="pug">
div(v-scrollanimation)
    v-container(fluid).d-none.d-md-block.rojo
      v-row.mt-12
      v-row.mt-12
      v-row.mt-12.ml-3
        div.rectangulo.secundario.mr-5.mt-2
        h1.font-weight-light.white--text.mr-2 ÚLTIMAS NOVEDADES
      v-row.mt-8
        v-col(cols=6)
          v-container.ma-7.pr-12
            Novedad_item(v-scrollanimation :entrada='Registro_de_entradas[0]' 
            :key='"inicial"' desde_componente primer_elemento)
        v-col(cols=6)
          v-container.mt-7.pl-7
            v-row
              v-col(v-scrollanimation cols=6 v-for='entrada, i in Registro_de_segundo_a_cuarto' :key='i')
                Novedad_item(:entrada='entrada' desde_componente)
      v-row.mt-12.pr-6.pl-6.ml-12.mr-1.mb-12
        v-spacer
        v-hover(v-slot='{hover}')
          div.puntor
            h2(v-if='hover' @click='dirigir_a()').font-weight-light.terciario--text.mr-12 + Más novedades
            h2(v-else).font-weight-light.white--text.mr-12 + Más novedades
        
    //-Móvil
    v-container(fluid).d-md-none.rojo
      v-row.mt-12.ml-3
        div.rectangulo_movil.secundario.mr-5.mt-2
        h2.font-weight-light.white--text.mr-2.mt-1 ÚLTIMAS NOTICIAS
      v-row.mt-8
        v-container.mt-7.pl-6.pr-6
            v-row
              v-col(cols=12 v-for='entrada, i in Registro_de_primero_a_segundo' :key='i')
                Novedad_item(:entrada='entrada' desde_componente)
      v-row.mt-12
      v-row(align='center' justify='center').mt-12.rojo
          h2( @click='dirigir_a()').font-weight-light.white--text + Más novedades
      v-row.mt-12
      v-row.mt-12
      
</template>
<script>
export default {
  components: {
    Novedad_item: ()=>import('../vistas/blog/componentes/novedades/novedad_item.vue'),
  },
  methods: {
    dirigir_a(){
      this.$router.replace({name: 'blog', params: {}})
      this.$vuetify.goTo("#navegador")
    },
  },
  computed: {
  },
}
</script>
<style lang="sass" scoped>
.negrita
  font-style: bold!important
.divider_inicial
  max-height: 10px!important
  height: 9px!important
.rectangulo
  width: 14px
  height: 33px
.rectangulo_movil
  width: 10px
  height: 33px
.puntor
  cursor: pointer
.before-enter
  opacity: 0
  transform: translateY(-100%)
  transition: all 1.5s ease-out   
.enter
  opacity: 1
  transform: translateX(0px)
</style>
